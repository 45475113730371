
import useCalendar from '~/hooks/useCalendar'

import ArchiveSvg from '~/static/icon/archive.svg'

import { fetchPosts, fetchTags } from '~/api/blog'

const HistoryTags = () => import('~/components/HistoryTags.vue')
const PostCard = () => import('~/components/PostCard.vue')
const GoogleAdsense = () => import('~/components/GoogleAdsense.vue')

export default {
  components: {
    ArchiveSvg,
    HistoryTags,
    PostCard,
    GoogleAdsense
  },
  setup(props, ctx) {
    return { ...useCalendar(props) }
  },
  async asyncData({ $sentry, $config }) {
    try {
      return {
        posts: await fetchPosts($config),
        tags: await fetchTags($config)
      }
    } catch (error) {
      $sentry.captureException(error)
    }
  }
}
