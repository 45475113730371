import { render, staticRenderFns } from "./index.vue?vue&type=template&id=304db5f7&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PostCard: require('/app/app/components/PostCard.vue').default,GoogleAdsense: require('/app/app/components/GoogleAdsense.vue').default,HistoryTags: require('/app/app/components/HistoryTags.vue').default})
